
    <template>
      <section class="content element-doc">
        <h2>Popconfirm 气泡确认框</h2>
<p>点击元素，弹出气泡确认框。</p>
<h3>基础用法</h3>
<p>Popconfirm 的属性与 Popover 很类似，因此对于重复属性，请参考 Popover 的文档，在此文档中不做详尽解释。</p>
<demo-block>
        <div><p>在 Popconfirm 中，只有 <code>title</code> 属性可用，<code>content</code> 属性不会被展示。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-popconfirm title=&quot;这是一段内容确定删除吗？&quot;&gt;
    &lt;template v-slot:reference&gt;
      &lt;el-button&gt;删除&lt;/el-button&gt;
    &lt;/template&gt;
  &lt;/el-popconfirm&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>自定义</h3>
<p>可以在 Popconfirm 中自定义内容。</p>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-popconfirm
    confirmButtonText=&quot;好的&quot;
    cancelButtonText=&quot;不用了&quot;
    icon=&quot;el-icon-info&quot;
    iconColor=&quot;red&quot;
    title=&quot;这是一段内容确定删除吗？&quot;
  &gt;
    &lt;template v-slot:reference&gt;
      &lt;el-button&gt;删除&lt;/el-button&gt;
    &lt;/template&gt;
  &lt;/el-popconfirm&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>title</td>
<td>标题</td>
<td>String</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>confirmButtonText</td>
<td>确认按钮文字</td>
<td>String</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>cancelButtonText</td>
<td>取消按钮文字</td>
<td>String</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>confirmButtonType</td>
<td>确认按钮类型</td>
<td>String</td>
<td>—</td>
<td>Primary</td>
</tr>
<tr>
<td>cancelButtonType</td>
<td>取消按钮类型</td>
<td>String</td>
<td>—</td>
<td>Text</td>
</tr>
<tr>
<td>icon</td>
<td>Icon</td>
<td>String</td>
<td>—</td>
<td>el-icon-question</td>
</tr>
<tr>
<td>iconColor</td>
<td>Icon 颜色</td>
<td>String</td>
<td>—</td>
<td>#f90</td>
</tr>
<tr>
<td>hideIcon</td>
<td>是否隐藏 Icon</td>
<td>Boolean</td>
<td>—</td>
<td>false</td>
</tr>
</tbody>
</table>
<h3>Slot</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>reference</td>
<td>触发 Popconfirm 显示的 HTML 元素</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>onConfirm</td>
<td>点击确认按钮时触发</td>
<td>—</td>
</tr>
<tr>
<td>onCancel</td>
<td>点击取消按钮时触发</td>
<td>—</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")

  return (_openBlock(), _createBlock(_component_el_popconfirm, { title: "这是一段内容确定删除吗？" }, {
    reference: _withCtx(() => [
      _createVNode(_component_el_button, null, {
        default: _withCtx(() => [
          _createTextVNode("删除")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")

  return (_openBlock(), _createBlock(_component_el_popconfirm, {
    confirmButtonText: "好的",
    cancelButtonText: "不用了",
    icon: "el-icon-info",
    iconColor: "red",
    title: "这是一段内容确定删除吗？"
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_el_button, null, {
        default: _withCtx(() => [
          _createTextVNode("删除")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  